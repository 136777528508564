/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
	/****************/
	/***** color ****/
	/****************/

	/** primary **/
	--ion-color-primary: #3880ff;
	--ion-color-primary-rgb: 56, 128, 255;
	--ion-color-primary-contrast: #fff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #3171e0;
	--ion-color-primary-tint: #4c8dff;

	/** secondary **/
	--ion-color-secondary: #3dc2ff;
	--ion-color-secondary-rgb: 61, 194, 255;
	--ion-color-secondary-contrast: #fff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #36abe0;
	--ion-color-secondary-tint: #50c8ff;

	/** tertiary **/
	--ion-color-tertiary: #5260ff;
	--ion-color-tertiary-rgb: 82, 96, 255;
	--ion-color-tertiary-contrast: #fff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #4854e0;
	--ion-color-tertiary-tint: #6370ff;

	/** success **/
	--ion-color-success: #60a54e;
	--ion-color-success-rgb: 96,165,78;
	--ion-color-success-contrast: #fff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #549145;
	--ion-color-success-tint: #70ae60;

	/** warning **/
	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	/** danger **/
	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #fff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	/** dark **/
	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 36, 40;
	--ion-color-dark-contrast: #fff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	/** medium **/
	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #fff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	/** light **/
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;



	/** thrilling-blue **/
	--ion-color-thrilling-blue: #79d8fb;
	--ion-color-thrilling-blue-rgb: 121,216,251;
	--ion-color-thrilling-blue-contrast: #000;
	--ion-color-thrilling-blue-contrast-rgb: 0,0,0;
	--ion-color-thrilling-blue-shade: #6abedd;
	--ion-color-thrilling-blue-tint: #86dcfb;

	/** thrilling-orange **/
	--ion-color-thrilling-orange: #ff6a4d;
	--ion-color-thrilling-orange-rgb: 255,106,77;
	--ion-color-thrilling-orange-contrast: #fff;
	--ion-color-thrilling-orange-contrast-rgb: 255, 255, 255;
	--ion-color-thrilling-orange-shade: #e05d44;
	--ion-color-thrilling-orange-tint: #ff795f;

	/** thrilling-orange-light **/
	--ion-color-thrilling-orange-light: #ff8450;
	--ion-color-thrilling-orange-light-rgb: 255,132,80;
	--ion-color-thrilling-orange-light-contrast: #000;
	--ion-color-thrilling-orange-light-contrast-rgb: 0,0,0;
	--ion-color-thrilling-orange-light-shade: #e07446;
	--ion-color-thrilling-orange-light-tint: #ff9062;

	/** thrilling-pink **/
	--ion-color-thrilling-pink: #ffb6db;
	--ion-color-thrilling-pink-rgb: 255,182,219;
	--ion-color-thrilling-pink-contrast: #000;
	--ion-color-thrilling-pink-contrast-rgb: 0,0,0;
	--ion-color-thrilling-pink-shade: #e0a0c1;
	--ion-color-thrilling-pink-tint: #ffbddf;

	/** thrilling-pink-light **/
	--ion-color-thrilling-pink-light: #ffedf6;
	--ion-color-thrilling-pink-light-rgb: 255,237,246;
	--ion-color-thrilling-pink-light-contrast: #000;
	--ion-color-thrilling-pink-light-contrast-rgb: 0,0,0;
	--ion-color-thrilling-pink-light-shade: #e0d1d8;
	--ion-color-thrilling-pink-light-tint: #ffeff7;

	/** thrilling-lime **/
	--ion-color-thrilling-lime: #e5ff00;
	--ion-color-thrilling-lime-rgb: 229,255,0;
	--ion-color-thrilling-lime-contrast: #000;
	--ion-color-thrilling-lime-contrast-rgb: 0,0,0;
	--ion-color-thrilling-lime-shade: #cae000;
	--ion-color-thrilling-lime-tint: #e8ff1a;



	/** system-red **/
	--ion-color-system-red: #ff3e13;
	--ion-color-system-red-rgb: 255,62,19;
	--ion-color-system-red-contrast: #fff;
	--ion-color-system-red-contrast-rgb: 255,255,255;
	--ion-color-system-red-shade: #e03711;
	--ion-color-system-red-tint: #ff512b;

	/** system-blue **/
	--ion-color-system-blue: #1b59f8;
	--ion-color-system-blue-rgb: 27,89,248;
	--ion-color-system-blue-contrast: #fff;
	--ion-color-system-blue-contrast-rgb: 255,255,255;
	--ion-color-system-blue-shade: #184eda;
	--ion-color-system-blue-tint: #326af9;

	/** system-purple **/
	--ion-color-system-purple: #7b61ff;
	--ion-color-system-purple-rgb: 123,97,255;
	--ion-color-system-purple-contrast: #fff;
	--ion-color-system-purple-contrast-rgb: 255,255,255;
	--ion-color-system-purple-shade: #6c55e0;
	--ion-color-system-purple-tint: #8871ff;



	/** black **/
	--ion-color-black: #000;
	--ion-color-black-rgb: 0,0,0;
	--ion-color-black-contrast: #fff;
	--ion-color-black-contrast-rgb: 255,255,255;
	--ion-color-black-shade: #000;
	--ion-color-black-tint: #1a1a1a;

	/** white **/
	--ion-color-white: #fff;
	--ion-color-white-rgb: 255,255,255;
	--ion-color-white-contrast: #000;
	--ion-color-white-contrast-rgb: 0,0,0;
	--ion-color-white-shade: #e0e0e0;
	--ion-color-white-tint: #fff;

	/** gray-darker **/
	// darker - 70
	// dark - 50
	// medium - 30
	// light - 20
	// lighter - 10
	// lightest - 4
	--ion-color-gray-darker: #4d4d4d;
	--ion-color-gray-darker-rgb: 77,77,77;
	--ion-color-gray-darker-contrast: #fff;
	--ion-color-gray-darker-contrast-rgb: 255,255,255;
	--ion-color-gray-darker-shade: #444;
	--ion-color-gray-darker-tint: #5f5f5f;

	/** gray-dark **/
	--ion-color-gray-dark: #808080;
	--ion-color-gray-dark-rgb: 128,128,128;
	--ion-color-gray-dark-contrast: #fff;
	--ion-color-gray-dark-contrast-rgb: 0,0,0;
	--ion-color-gray-dark-shade: #717171;
	--ion-color-gray-dark-tint: #8d8d8d;

	/** gray-medium **/
	--ion-color-gray-medium: #b3b3b3;
	--ion-color-gray-medium-rgb: 179,179,179;
	--ion-color-gray-medium-contrast: #fff;
	--ion-color-gray-medium-contrast-rgb: 0,0,0;
	--ion-color-gray-medium-shade: #9e9e9e;
	--ion-color-gray-medium-tint: #bbb;

	/** gray-light **/
	--ion-color-gray-light: #ccc;
	--ion-color-gray-light-rgb: 204,204,204;
	--ion-color-gray-light-contrast: #000;
	--ion-color-gray-light-contrast-rgb: 0,0,0;
	--ion-color-gray-light-shade: #b4b4b4;
	--ion-color-gray-light-tint: #d1d1d1;

	/** gray-lighter **/
	--ion-color-gray-lighter: #e6e6e6;
	--ion-color-gray-lighter-rgb: 230,230,230;
	--ion-color-gray-lighter-contrast: #000;
	--ion-color-gray-lighter-contrast-rgb: 0,0,0;
	--ion-color-gray-lighter-shade: #cacaca;
	--ion-color-gray-lighter-tint: #e9e9e9;

	/** gray-lightest **/
	--ion-color-gray-lightest: #f5f5f5;
	--ion-color-gray-lightest-rgb: 245,245,245;
	--ion-color-gray-lightest-contrast: #000;
	--ion-color-gray-lightest-contrast-rgb: 0,0,0;
	--ion-color-gray-lightest-shade: #d8d8d8;
	--ion-color-gray-lightest-tint: #f6f6f6;


	/****************/
	/** typography **/
	/****************/

	/** font family **/
	--font-text-primary: 'Inter', sans-serif;
	--font-text-secondary: 'Oswald', sans-serif;
	--font-text-tertiary: 'Graphik', sans-serif;
	--font-display-primary: 'Playfair Display', serif;
	--font-display-secondary: 'Montserrat', sans-serif;
	--font-display-tertiary: 'Schnyder S', sans-serif;

	/** type scale **/
	--text-size-xs: 12px;
	--text-size-sm: 14px;
	--text-size-md: 16px;
	--text-size-lg: 17px;
	--text-size-xl: 24px;

	/** line height **/
	--text-height-xs: 15px;
	--text-height-sm: 18px;
	--text-height-md: 20px;
	--text-height-lg: 22px;
	--text-height-xl: 29px;
}

// @media (prefers-color-scheme: dark) {
// 	/*
//    * Dark Colors
//    * -------------------------------------------
//    */

// 	body {
// 		--ion-color-primary: #428cff;
// 		--ion-color-primary-rgb: 66, 140, 255;
// 		--ion-color-primary-contrast: #fff;
// 		--ion-color-primary-contrast-rgb: 255, 255, 255;
// 		--ion-color-primary-shade: #3a7be0;
// 		--ion-color-primary-tint: #5598ff;

// 		--ion-color-secondary: #50c8ff;
// 		--ion-color-secondary-rgb: 80, 200, 255;
// 		--ion-color-secondary-contrast: #fff;
// 		--ion-color-secondary-contrast-rgb: 255, 255, 255;
// 		--ion-color-secondary-shade: #46b0e0;
// 		--ion-color-secondary-tint: #62ceff;

// 		--ion-color-tertiary: #6a64ff;
// 		--ion-color-tertiary-rgb: 106, 100, 255;
// 		--ion-color-tertiary-contrast: #fff;
// 		--ion-color-tertiary-contrast-rgb: 255, 255, 255;
// 		--ion-color-tertiary-shade: #5d58e0;
// 		--ion-color-tertiary-tint: #7974ff;

// 		--ion-color-success: #2fdf75;
// 		--ion-color-success-rgb: 47, 223, 117;
// 		--ion-color-success-contrast: #000;
// 		--ion-color-success-contrast-rgb: 0, 0, 0;
// 		--ion-color-success-shade: #29c467;
// 		--ion-color-success-tint: #44e283;

// 		--ion-color-warning: #ffd534;
// 		--ion-color-warning-rgb: 255, 213, 52;
// 		--ion-color-warning-contrast: #000;
// 		--ion-color-warning-contrast-rgb: 0, 0, 0;
// 		--ion-color-warning-shade: #e0bb2e;
// 		--ion-color-warning-tint: #ffd948;

// 		--ion-color-danger: #ff4961;
// 		--ion-color-danger-rgb: 255, 73, 97;
// 		--ion-color-danger-contrast: #fff;
// 		--ion-color-danger-contrast-rgb: 255, 255, 255;
// 		--ion-color-danger-shade: #e04055;
// 		--ion-color-danger-tint: #ff5b71;

// 		--ion-color-dark: #f4f5f8;
// 		--ion-color-dark-rgb: 244, 245, 248;
// 		--ion-color-dark-contrast: #000;
// 		--ion-color-dark-contrast-rgb: 0, 0, 0;
// 		--ion-color-dark-shade: #d7d8da;
// 		--ion-color-dark-tint: #f5f6f9;

// 		--ion-color-medium: #989aa2;
// 		--ion-color-medium-rgb: 152, 154, 162;
// 		--ion-color-medium-contrast: #000;
// 		--ion-color-medium-contrast-rgb: 0, 0, 0;
// 		--ion-color-medium-shade: #86888f;
// 		--ion-color-medium-tint: #a2a4ab;

// 		--ion-color-light: #222428;
// 		--ion-color-light-rgb: 34, 36, 40;
// 		--ion-color-light-contrast: #fff;
// 		--ion-color-light-contrast-rgb: 255, 255, 255;
// 		--ion-color-light-shade: #1e2023;
// 		--ion-color-light-tint: #383a3e;
// 	}

// 	/*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

// 	.ios body {
// 		--ion-background-color: #000;
// 		--ion-background-color-rgb: 0, 0, 0;

// 		--ion-text-color: #fff;
// 		--ion-text-color-rgb: 255, 255, 255;

// 		--ion-color-step-50: #0d0d0d;
// 		--ion-color-step-100: #1a1a1a;
// 		--ion-color-step-150: #262626;
// 		--ion-color-step-200: #333;
// 		--ion-color-step-250: #404040;
// 		--ion-color-step-300: #4d4d4d;
// 		--ion-color-step-350: #595959;
// 		--ion-color-step-400: #666;
// 		--ion-color-step-450: #737373;
// 		--ion-color-step-500: #808080;
// 		--ion-color-step-550: #8c8c8c;
// 		--ion-color-step-600: #999;
// 		--ion-color-step-650: #a6a6a6;
// 		--ion-color-step-700: #b3b3b3;
// 		--ion-color-step-750: #bfbfbf;
// 		--ion-color-step-800: #ccc;
// 		--ion-color-step-850: #d9d9d9;
// 		--ion-color-step-900: #e6e6e6;
// 		--ion-color-step-950: #f2f2f2;

// 		--ion-item-background: #000;

// 		--ion-card-background: #1c1c1d;
// 	}

// 	.ios ion-modal {
// 		--ion-background-color: var(--ion-color-step-100);
// 		--ion-toolbar-background: var(--ion-color-step-150);
// 		--ion-toolbar-border-color: var(--ion-color-step-250);
// 	}

// 	/*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

// 	.md body {
// 		--ion-background-color: #121212;
// 		--ion-background-color-rgb: 18, 18, 18;

// 		--ion-text-color: #fff;
// 		--ion-text-color-rgb: 255, 255, 255;

// 		--ion-border-color: #222;

// 		--ion-color-step-50: #1e1e1e;
// 		--ion-color-step-100: #2a2a2a;
// 		--ion-color-step-150: #363636;
// 		--ion-color-step-200: #414141;
// 		--ion-color-step-250: #4d4d4d;
// 		--ion-color-step-300: #595959;
// 		--ion-color-step-350: #656565;
// 		--ion-color-step-400: #717171;
// 		--ion-color-step-450: #7d7d7d;
// 		--ion-color-step-500: #898989;
// 		--ion-color-step-550: #949494;
// 		--ion-color-step-600: #a0a0a0;
// 		--ion-color-step-650: #acacac;
// 		--ion-color-step-700: #b8b8b8;
// 		--ion-color-step-750: #c4c4c4;
// 		--ion-color-step-800: #d0d0d0;
// 		--ion-color-step-850: #dbdbdb;
// 		--ion-color-step-900: #e7e7e7;
// 		--ion-color-step-950: #f3f3f3;

// 		--ion-item-background: #1e1e1e;

// 		--ion-toolbar-background: #1f1f1f;

// 		--ion-tab-bar-background: #1f1f1f;

// 		--ion-card-background: #1e1e1e;
// 	}
// }
