.ion-color-thrilling-blue {
	--ion-color-base: var(--ion-color-thrilling-blue);
	--ion-color-base-rgb: var(--ion-color-thrilling-blue-rgb);
	--ion-color-contrast: var(--ion-color-thrilling-blue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-thrilling-blue-contrast-rgb);
	--ion-color-shade: var(--ion-color-thrilling-blue-shade);
	--ion-color-tint: var(--ion-color-thrilling-blue-tint);
}

.ion-color-thrilling-orange {
	--ion-color-base: var(--ion-color-thrilling-orange);
	--ion-color-base-rgb: var(--ion-color-thrilling-orange-rgb);
	--ion-color-contrast: var(--ion-color-thrilling-orange-contrast);
	--ion-color-contrast-rgb: var(--ion-color-thrilling-orange-contrast-rgb);
	--ion-color-shade: var(--ion-color-thrilling-orange-shade);
	--ion-color-tint: var(--ion-color-thrilling-orange-tint);
}

.ion-color-thrilling-orange-light {
	--ion-color-base: var(--ion-color-thrilling-orange-light);
	--ion-color-base-rgb: var(--ion-color-thrilling-orange-light-rgb);
	--ion-color-contrast: var(--ion-color-thrilling-orange-light-contrast);
	--ion-color-contrast-rgb: var(--ion-color-thrilling-orange-light-contrast-rgb);
	--ion-color-shade: var(--ion-color-thrilling-orange-light-shade);
	--ion-color-tint: var(--ion-color-thrilling-orange-light-tint);
}

.ion-color-thrilling-pink {
	--ion-color-base: var(--ion-color-thrilling-pink);
	--ion-color-base-rgb: var(--ion-color-thrilling-pink-rgb);
	--ion-color-contrast: var(--ion-color-thrilling-pink-contrast);
	--ion-color-contrast-rgb: var(--ion-color-thrilling-pink-contrast-rgb);
	--ion-color-shade: var(--ion-color-thrilling-pink-shade);
	--ion-color-tint: var(--ion-color-thrilling-pink-tint);
}

.ion-color-thrilling-pink-light {
	--ion-color-base: var(--ion-color-thrilling-pink-light);
	--ion-color-base-rgb: var(--ion-color-thrilling-pink-light-rgb);
	--ion-color-contrast: var(--ion-color-thrilling-pink-light-contrast);
	--ion-color-contrast-rgb: var(--ion-color-thrilling-pink-light-contrast-rgb);
	--ion-color-shade: var(--ion-color-thrilling-pink-light-shade);
	--ion-color-tint: var(--ion-color-thrilling-pink-light-tint);
}

.ion-color-thrilling-lime {
	--ion-color-base: var(--ion-color-thrilling-lime);
	--ion-color-base-rgb: var(--ion-color-thrilling-lime-rgb);
	--ion-color-contrast: var(--ion-color-thrilling-lime-contrast);
	--ion-color-contrast-rgb: var(--ion-color-thrilling-lime-contrast-rgb);
	--ion-color-shade: var(--ion-color-thrilling-lime-shade);
	--ion-color-tint: var(--ion-color-thrilling-lime-tint);
}

.ion-color-system-red {
	--ion-color-base: var(--ion-color-system-red);
	--ion-color-base-rgb: var(--ion-color-system-red-rgb);
	--ion-color-contrast: var(--ion-color-system-red-contrast);
	--ion-color-contrast-rgb: var(--ion-color-system-red-contrast-rgb);
	--ion-color-shade: var(--ion-color-system-red-shade);
	--ion-color-tint: var(--ion-color-system-red-tint);
}

.ion-color-system-blue {
	--ion-color-base: var(--ion-color-system-blue);
	--ion-color-base-rgb: var(--ion-color-system-blue-rgb);
	--ion-color-contrast: var(--ion-color-system-blue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-system-blue-contrast-rgb);
	--ion-color-shade: var(--ion-color-system-blue-shade);
	--ion-color-tint: var(--ion-color-system-blue-tint);
}

.ion-color-system-purple {
	--ion-color-base: var(--ion-color-system-purple);
	--ion-color-base-rgb: var(--ion-color-system-purple-rgb);
	--ion-color-contrast: var(--ion-color-system-purple-contrast);
	--ion-color-contrast-rgb: var(--ion-color-system-purple-contrast-rgb);
	--ion-color-shade: var(--ion-color-system-purple-shade);
	--ion-color-tint: var(--ion-color-system-purple-tint);
}

.ion-color-black {
	--ion-color-base: var(--ion-color-black);
	--ion-color-base-rgb: var(--ion-color-black-rgb);
	--ion-color-contrast: var(--ion-color-black-contrast);
	--ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
	--ion-color-shade: var(--ion-color-black-shade);
	--ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-white {
	--ion-color-base: var(--ion-color-white);
	--ion-color-base-rgb: var(--ion-color-white-rgb);
	--ion-color-contrast: var(--ion-color-white-contrast);
	--ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
	--ion-color-shade: var(--ion-color-white-shade);
	--ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-gray-darker {
	--ion-color-base: var(--ion-color-gray-darker);
	--ion-color-base-rgb: var(--ion-color-gray-darker-rgb);
	--ion-color-contrast: var(--ion-color-gray-darker-contrast);
	--ion-color-contrast-rgb: var(--ion-color-gray-darker-contrast-rgb);
	--ion-color-shade: var(--ion-color-gray-darker-shade);
	--ion-color-tint: var(--ion-color-gray-darker-tint);
}

.ion-color-gray-dark {
	--ion-color-base: var(--ion-color-gray-dark);
	--ion-color-base-rgb: var(--ion-color-gray-dark-rgb);
	--ion-color-contrast: var(--ion-color-gray-dark-contrast);
	--ion-color-contrast-rgb: var(--ion-color-gray-dark-contrast-rgb);
	--ion-color-shade: var(--ion-color-gray-dark-shade);
	--ion-color-tint: var(--ion-color-gray-dark-tint);
}

.ion-color-gray-medium {
	--ion-color-base: var(--ion-color-gray-medium);
	--ion-color-base-rgb: var(--ion-color-gray-medium-rgb);
	--ion-color-contrast: var(--ion-color-gray-medium-contrast);
	--ion-color-contrast-rgb: var(--ion-color-gray-medium-contrast-rgb);
	--ion-color-shade: var(--ion-color-gray-medium-shade);
	--ion-color-tint: var(--ion-color-gray-medium-tint);
}

.ion-color-gray-light {
	--ion-color-base: var(--ion-color-gray-light);
	--ion-color-base-rgb: var(--ion-color-gray-light-rgb);
	--ion-color-contrast: var(--ion-color-gray-light-contrast);
	--ion-color-contrast-rgb: var(--ion-color-gray-light-contrast-rgb);
	--ion-color-shade: var(--ion-color-gray-light-shade);
	--ion-color-tint: var(--ion-color-gray-light-tint);
}

.ion-color-gray-lighter {
	--ion-color-base: var(--ion-color-gray-lighter);
	--ion-color-base-rgb: var(--ion-color-gray-lighter-rgb);
	--ion-color-contrast: var(--ion-color-gray-lighter-contrast);
	--ion-color-contrast-rgb: var(--ion-color-gray-lighter-contrast-rgb);
	--ion-color-shade: var(--ion-color-gray-lighter-shade);
	--ion-color-tint: var(--ion-color-gray-lighter-tint);
}

.ion-color-gray-lightest {
	--ion-color-base: var(--ion-color-gray-lightest);
	--ion-color-base-rgb: var(--ion-color-gray-lightest-rgb);
	--ion-color-contrast: var(--ion-color-gray-lightest-contrast);
	--ion-color-contrast-rgb: var(--ion-color-gray-lightest-contrast-rgb);
	--ion-color-shade: var(--ion-color-gray-lightest-shade);
	--ion-color-tint: var(--ion-color-gray-lightest-tint);
}
