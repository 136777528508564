/** 
* main text classes 
**/

$main-text:
	'body-2' 'xs',
	'body-1' 'sm',
	'title' 'md',
	'label' 'lg';

@each $name, $size in $main-text {
	.#{$name} {
		font-weight: 400;
	}

	.#{$name}-semi {
		font-weight: 500;
	}

	.#{$name}-em {
		font-weight: 600;
	}

	.#{$name}, .#{$name}-semi, .#{$name}-em {
		font-family: var(--font-text-primary);
		font-size: var(--text-size-#{$size});
		line-height: var(--text-height-#{$size});
	}
}

.headline {
	font-family: var(--font-text-primary);
	font-weight: 700;
	font-size: var(--text-size-xl);
	line-height: var(--text-height-xl);
}

/** 
* display text classes
**/

.listing-title,
.listing-title-lg {
	font-family: var(--font-display-primary);
	font-weight: 400;
	letter-spacing: 2%;
}

.listing-title {
	font-size: 18px;
	line-height: 24px;
}

.listing-title-lg {
	font-size: 24px;
	line-height: 32px;
}

.listing-price,
.listing-price-lg {
	font-family: var(--font-text-secondary);
	font-weight: 500;
	letter-spacing: 1px;
}

.listing-price {
	font-size: 14px;
	line-height: 21px;
}

.listing-price-lg {
	font-size: 20px;
	line-height: 30px;
}

.nav-title {
	font-family: var(--font-display-secondary);
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.sku {
	font-family: var(--font-display-secondary);
	font-weight: 600;
	font-size: 12px;
	line-height: 20px;
	letter-spacing: 2px;
}

.segmented-control {
	font-family: var(--font-text-primary);
	font-weight: 600;
	font-size: 11px;
	line-height: 13px;
	text-transform: uppercase;
	letter-spacing: 0.5px;
}

.description-text {
	font-family: var(--font-text-primary);
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: 1%;
}

.store-title {
	font-family: var(--font-display-tertiary);
	font-weight: 600;
	font-size: 24px;
	line-height: 27px;
	letter-spacing: 2%;
	text-transform: uppercase;
}

.store-subheader {
	font-family: var(--font-text-tertiary);
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: -0.15px;
}

.store-text {
	font-family: var(--font-text-tertiary);
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

.store-image-text {
	font-family: var(--font-text-primary);
	font-weight: 400;
	font-size: 16px;
}
