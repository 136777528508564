@import './helpers/colors';
@import './helpers/fonts';
@import './helpers/typography';

// GLOBAL STYLES

h1,
h2,
h3,
h4,
h5,
p {
	margin: 0;
}

ul,
li {
	list-style: none;
	padding: 0;
}

ion-content {
	--padding-start: 24px;
	--padding-end: 24px;
}

ion-item {
	--padding-start: 0;
	--padding-end: 0;
}

// SHARED CLASSES

.center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.searchbar {
	padding: 8px 0;
	--background: var(--ion-color-gray-lightest);
	--icon-color: var(--ion-color-gray-dark);
	--cancel-button-color: var(--icon-color-gray-medium);
	--placeholder-opacity: 0.5;
}

.control-segment {
	--background: transparent;
	padding: 6px 0;
	margin-bottom: 12px;
}

.control-segment-btn {
	@extend .segmented-control;

	--background: transparent;
	--border-radius: 20px;
	--color: var(--ion-color-gray-dark);
	--color-checked: var(--ion-color-black);
	--indicator-box-shadow: none;
	--indicator-color: var(--ion-color-gray-lightest);
	--indicator-height: 21px;
	--border-width: 0;
	margin: 0;
	min-height: 21px;
}

.image-carousal {
	margin: 0 -24px 32px;
	padding: 0 20px;
	display: flex;
	flex-flow: row nowrap;
	overflow-x: auto;
	gap: 13.5px;

	li,
	ion-thumbnail {
		flex-shrink: 0;

		&:first-child {
			margin-left: auto;
		}

		&:last-child {
			margin-right: auto;
		}
	}
}

.text-input {
	@extend .title;
	--padding-bottom: 14px;
	--padding-top: 14px;
	--padding-start: 16.5px;
	--padding-end: 16.5px;
	border: 1px solid var(--ion-color-black);
	border-radius: 4px;
	margin-bottom: 8px;
}

.text-area {
	@extend .title;
	--padding-bottom: 16px;
	--padding-top: 16px;
	--padding-start: 16px;
	--padding-end: 16px;
	border: 1px solid var(--ion-color-gray-medium);
	border-radius: 4px;
	margin-bottom: 8px;
}

.product-list-item {
	height: 125px;
	margin-bottom: 12px;
}

.prompt-message {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100%;
	padding: 0 40px;

	h2 {
		@extend .label-em;
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 16px;
	}

	p {
		@extend .title;
		color: var(--ion-color-gray-medium);
	}
}

.footer {
	filter: drop-shadow(0 0 2px var(--ion-color-gray-dark));

	ion-button {
		margin: 16px auto;
		padding: 0 24px;
	}
}
